import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllLicensesByResellerId(resellerId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/getAllLicensesByResellerId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getLicenseById(licenseId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(licenseId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/getLicenseById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function deploy(licenseId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(licenseId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/deploy", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getDeployStatus(licenseId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(licenseId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/getDeployStatus", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateLicense(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/updateLicense", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertLicense(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/insertLicense", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function setupBios(licenceNumber, firstName, lastName, email, password) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            licenceNumber: licenceNumber,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/setupBios", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deployWithoutToken(LicenseNumber) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(LicenseNumber),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/deployWithoutToken", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkStoreName(storeName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(storeName),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/checkStoreName", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkCompanyKey(companyKey) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(companyKey),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/checkCompanyKey", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function registerNewLicense(license) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(license),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/licenses/registerNewLicense", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getPaymentLink(license) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            companyKey: license.companyKey,
            ownerFirstName: license.ownerFirstName,
            ownerLastName: license.ownerLastName,
            city: license.city,
            street: license.street,
            addressDetails: license.addressDetails,
            zipCode: license.zipCode,
            houseNumber: license.houseNumber,
            email: license.email,
            password: license.password,
            title: license.title,
            storeName: license.storeName,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/payment/getPaymentLink", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkPaymentAndDeploy(tid, licenseId, firstName, lastName, email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            tid: tid,
            licenseId: licenseId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/payment/checkPaymentAndDeploy", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
