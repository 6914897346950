import React, { Component } from "react";
import store from "../../../store";
import { getAllLicensesByResellerId, updateLicense, insertLicense } from "../../../actions/licensesActions";
import {
    Column,
    Editing,
    Paging,
    Texts,
    Pager,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { connect } from "react-redux";
import "./Licenses.scss";
import { Link } from "react-router-dom";
import { TreeList } from "devextreme-react";

class Licenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            licenses: []
        };

        this.handleInsert = this.handleInsert.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.editButtons = this.editButtons.bind(this);
    }

    render() {
        return (
            <div className="licenses">
                <h1>Lizenzen</h1>
                <TreeList
                    dataSource={this.state.licenses}
                    showBorders={true}
                    showRowLines={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    onInitialized={this.handleInit}
                    onRowUpdated={this.handleUpdate}
                    onRowInserting={this.handleInsert}
                    keyExpr="LicenseId"
                    parentIdExpr="parentLicenseId"
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                    <Editing allowUpdating={false} allowAdding={true} allowDeleting={false}>
                        <Texts addRow="Lizenz hinzufügen" addRowToNode="Hinzufügen" editRow="Bearbeiten" cancelRowChanges="Abbrechen" saveRowChanges="Speichern" />
                    </Editing>
                    <Column dataField="StoreName" caption={"Name"}>
                        <RequiredRule />
                    </Column>
                    <Column dataField="Owner" caption={"Inhaber / GF"}>
                        <RequiredRule />
                    </Column>
                    <Column dataField="ValidityDate" dataType="date" caption={"Lizenzablauf"} />
                    <Column dataField="LicenseNumber" caption={"Lizenznummer"} allowEditing={false} />
                    <Column
                        key={3}
                        dataField={"LicenseId"}
                        caption={""}
                        cellRender={this.editButtons}
                        allowEditing={false}
                        width={240}
                    />
                </TreeList>
            </div>
        );
    }

    editButtons(props) {
        return (
            <div style={{display: "flex", justifyContent: "spaceBetween"}}>
                <Link style={{marginRight: "20px"}} to={`${this.props.match.url}/${props.data.LicenseId}`}>
                    <span>Lizenz bearbeiten</span>
                </Link>
                <Link to={`${this.props.match.url}/webshops/${props.value}`}>
                    <span>Webshops</span>
                </Link>
            </div>
        );
    }

    handleInit(e) {
        e.component.beginCustomLoading();
        store.dispatch(getAllLicensesByResellerId(this.props.identity.user.resellerId)).then((json) => {
            this.setState({ licenses: json });
            e.component.endCustomLoading();
        });
    }

    handleUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateLicense(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleInsert(e) {
        e.component.beginCustomLoading();
        store.dispatch(insertLicense(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Licenses);
