import { Button } from "devextreme-react";
import { Form, GroupItem, Label, SimpleItem } from "devextreme-react/form";
import React from "react";
import { deploy, getDeployStatus, getLicenseById, updateLicense } from "../../../actions/licensesActions";
import store from "../../../store";

class License extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            licenseId: this.props.match.params.id,
            license: undefined,
            deployStatus: 0,
            deployStatusString: "",
        };

        this.onLicenseChanged = this.onLicenseChanged.bind(this);
        this.deployButton = this.deployButton.bind(this);
        this.deploy = this.deploy.bind(this);
        this.getDeployStatus = this.getDeployStatus.bind(this);
        this.onStoreNameChanged = this.onStoreNameChanged.bind(this);
    }

    componentDidMount() {
        if (this.state.licenseId) {
            store.dispatch(getLicenseById(this.state.licenseId)).then((result) => {
                console.log(result);
                this.setState({
                    license: result,
                    deployStatus: result.Container ? result.Container.deployStatus : 0,
                    deployStatusString: result.Container ? result.Container.deployStatusString : "",
                });
            });
        }
    }

    deploy() {
        this.setState({
            deployStatusString: "(1/4) Deploy gestartet",
            deployStatus: 1
        });
        store.dispatch(deploy(this.state.licenseId));
    }

    render() {
        return (
            <div>
                {this.state.license && (
                    <Form formData={this.state.license} disabled={this.state.loading}>
                        <GroupItem cssClass="first-group" colCount={4}>
                            <GroupItem colSpan={3}>
                                <SimpleItem
                                    dataField="StoreName"
                                    editorOptions={{ onValueChanged: this.onStoreNameChanged }}
                                    >
                                    <Label text={"Name"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Owner"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Inhaber / GF"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="LicenseNumber"
                                    disabled
                                    >
                                    <Label text={"Lizenznummer"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="companyKey"
                                    disabled
                                    >
                                    <Label text={"Unternehmensschlüssel"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ValidityDate"
                                    editorType="dxDateBox"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Lizenzablauf"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                            <SimpleItem
                                    dataField="Revoked"
                                    editorType="dxCheckBox"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Lizenz gesperrt"} />
                                </SimpleItem>
                                <SimpleItem render={this.deployButton} />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="second-group" colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="title"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Titel"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ownerLastName"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Nachname Inhaber"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="houseNumber"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Hausnummer"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Zip"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Postleitzahl"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="ownerFirstName"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Inhaber Vorname"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Street"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Straße"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="AddressDetails"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Adresszusatz"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="City"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Stadt"} />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="third-group" colCount={2}>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="deployFrontendUrl"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    disabled
                                    >
                                    <Label text={"Deploy-Frontend-Url"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="ratingEnabled"
                                    editorOptions={{ onValueChanged: this.onLicenseChanged }}
                                    >
                                    <Label text={"Bewertungen aktiviert"} />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem cssClass="fourth-group" colCount={2} disabled={this.state.license.Container === null}>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="Container.databaseServer"
                                    disabled
                                >
                                    <Label text={"Datenbank Server"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.databaseUser"
                                    disabled
                                >
                                    <Label text={"Datenbank Benutzer"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.containerCPU"
                                    disabled
                                >
                                    <Label text={"Container CPU"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.containerGroupName"
                                    disabled
                                >
                                    <Label text={"Container Gruppen Name"} />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem colSpan={1}>
                                <SimpleItem
                                    dataField="Container.databaseName"
                                    disabled
                                >
                                    <Label text={"Datenbank Name"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.databasePassword"
                                    disabled
                                >
                                    <Label text={"Datenbank Passwort"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.containerMemoryInGB"
                                    disabled
                                >
                                    <Label text={"Container Speicher in GB"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="Container.containerImage"
                                    disabled
                                >
                                    <Label text={"Container Image"} />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                )}
            </div>
        );
    }

    deployButton() {
        if (this.state.deployStatus === 0) {
            return (
                <div style={{ display: "grid", marginTop: 20 }}>
                    <Button
                        text={"Deploy"}
                        type="default"
                        onClick={this.deploy}
                    />
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>Deploy-Status: {this.state.deployStatusString}</div>
                    <Button text={"Status aktualisieren"} type="default" onClick={this.getDeployStatus} />
                </div>
            );
        }
    }

    getDeployStatus() {
        store.dispatch(getDeployStatus(this.state.licenseId)).then((result) => {
            this.setState({
                license: {
                    ...this.state.license,
                    deployStatusString: result.deployStatusString,
                },
            });
        });
    }

    onLicenseChanged(e) {
        if (this.state.license) {
            this.setState({
                loading: true,
            });
            store.dispatch(updateLicense(this.state.license)).then((result) => {
                this.setState({
                    loading: false,
                });
            });
        }
    }

    onStoreNameChanged(e) {
        if (this.state.license) {
            this.setState({
                loading: true,
            });
            store.dispatch(updateLicense(this.state.license)).then((result) => {
                this.setState({
                    loading: false,
                });
            });
        }
    }
}

export default License;
