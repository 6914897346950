import Config from "../Config";
import { history } from "../helpers/history";
import { authHeader } from "../helpers/requestHelpers";
import { userService } from "../services/UserService";
import { alertActions } from "./alertActions";
import { handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const userConstants = {
    REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    UPDATEUSERROLE_SUCCESS: "UPDATEUSERROLE_SUCCESS",
    UPDATEUSERPASSWORD_SUCCESS: "UPDATEUSERPASSWORD_SUCCESS",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    LOGOUT: "USERS_LOGOUT",

    GETALL_REQUEST: "USERS_GETALL_REQUEST",
    GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
    GETALL_FAILURE: "USERS_GETALL_FAILURE",

    GETALLROLES_REQUEST: "USERS_GETALLROLES_REQUEST",
    GETALLROLES_SUCCESS: "USERS_GETALLROLES_SUCCESS",
    GETALLROLES_FAILURE: "USERS_GETALLROLES_FAILURE",

    GETUSERROLES_REQUEST: "USERS_GETUSERROLES_REQUEST",
    GETUSERROLES_SUCCESS: "USERS_GETUSERROLES_SUCCESS",
    GETUSERROLES_FAILURE: "USERS_GETUSERROLES_FAILURE",

    GETUSERRIGHTS_REQUEST: "USERS_GETUSERRIGHTS_REQUEST",
    GETUSERRIGHTS_SUCCESS: "USERS_GETUSERRIGHTS_SUCCESS",
    GETUSERRIGHTS_FAILURE: "USERS_GETUSERRIGHTS_FAILURE",

    GETUSERHASRIGHTMENUCARD_REQUEST: "USERS_GETUSERHASRIGHTMENUCARD_REQUEST",
    GETUSERHASRIGHTMENUCARD_SUCCESS: "USERS_GETUSERHASRIGHTMENUCARD_SUCCESS",
    GETUSERHASRIGHTMENUCARD_FAILURE: "USERS_GETUSERHASRIGHTMENUCARD_FAILURE",

    GETUSERHASRIGHTEDITRECIPE_REQUEST: "USERS_GETUSERHASRIGHTEDITRECIPE_REQUEST",
    GETUSERHASRIGHTEDITRECIPE_SUCCESS: "USERS_GETUSERHASRIGHTEDITRECIPE_SUCCESS",
    GETUSERHASRIGHTEDITRECIPE_FAILURE: "USERS_GETUSERHASRIGHTEDITRECIPE_FAILURE",

    GETUSERHASRIGHTSETTINGS_REQUEST: "USERS_GETUSERHASRIGHTSETTINGS_REQUEST",
    GETUSERHASRIGHTSETTINGS_SUCCESS: "USERS_GETUSERHASRIGHTSETTINGS_SUCCESS",
    GETUSERHASRIGHTSETTINGS_FAILURE: "USERS_GETUSERHASRIGHTSETTINGS_FAILURE",

    DELETE_REQUEST: "USERS_DELETE_REQUEST",
    DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
    DELETE_FAILURE: "USERS_DELETE_FAILURE",
};

export const userActions = {
    login,
    logout,
    getAll,
    register,
    getAllRoles,
    getUserRoles,
    getUserRights,
    updateUserRole,
    getUserHasRightMenuCard,
    getUserHasRightEditRecipe,
    getUserHasRightSettings,
};

function login(username, password) {
    return (dispatch) => {
        dispatch(request({ username }));

        userService.login(username, password).then(
            (user) => {
                dispatch(success(user));
                history.push("/");
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll(user) {
    return (dispatch) => {
        dispatch(request());

        return userService.getAll(user).then(
            (users) => dispatch(success(users)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function getAllRoles() {
    return (dispatch) => {
        dispatch(request());

        return userService.getAllRoles().then(
            (roles) => dispatch(success(roles)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETALLROLES_REQUEST };
    }
    function success(roles) {
        return { type: userConstants.GETALLROLES_SUCCESS, roles };
    }
    function failure(error) {
        return { type: userConstants.GETALLROLES_FAILURE, error };
    }
}

function getUserRoles(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserRoles(user).then(
            (userroles) => dispatch(success(userroles)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERROLES_REQUEST };
    }
    function success(userroles) {
        return { type: userConstants.GETUSERROLES_SUCCESS, userroles };
    }
    function failure(error) {
        return { type: userConstants.GETUSERROLES_FAILURE, error };
    }
}

function getUserRights(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserRights(user).then(
            (userrights) => dispatch(success(userrights)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERRIGHTS_REQUEST };
    }
    function success(userrights) {
        return { type: userConstants.GETUSERRIGHTS_SUCCESS, userrights };
    }
    function failure(error) {
        return { type: userConstants.GETUSERRIGHTS_FAILURE, error };
    }
}

function getUserHasRightMenuCard(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserHasRightMenuCard(user).then(
            (userHasRightMenuCard) => dispatch(success(userHasRightMenuCard)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERHASRIGHTMENUCARD_REQUEST };
    }
    function success() {
        return { type: userConstants.GETUSERHASRIGHTMENUCARD_SUCCESS };
    }
    function failure() {
        return { type: userConstants.GETUSERHASRIGHTMENUCARD_FAILURE };
    }
}

function getUserHasRightEditRecipe(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserHasRightEditRecipe(user).then(
            (userHasRightEditRecipe) => dispatch(success(userHasRightEditRecipe)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERHASRIGHTEDITRECIPE_REQUEST };
    }
    function success() {
        return { type: userConstants.GETUSERHASRIGHTEDITRECIPE_SUCCESS };
    }
    function failure() {
        return { type: userConstants.GETUSERHASRIGHTEDITRECIPE_FAILURE };
    }
}

function getUserHasRightSettings(user) {
    return (dispatch) => {
        dispatch(request());

        userService.getUserHasRightSettings(user).then(
            (userHasRightSettings) => dispatch(success(userHasRightSettings)),
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: userConstants.GETUSERHASRIGHTSETTINGS_REQUEST };
    }
    function success() {
        return { type: userConstants.GETUSERHASRIGHTSETTINGS_SUCCESS };
    }
    function failure() {
        return { type: userConstants.GETUSERHASRIGHTSETTINGS_FAILURE };
    }
}

export function register(username, password, role) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            username: username,
            password: password,
            role: role,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/register", requestOptions).then((user) => {
            dispatch(success(user));
        });
    };

    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
}

export function updateUserPassword(newPassword) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(newPassword),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserPassword", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERPASSWORD_SUCCESS, user };
    }
}

export function updateUserData(userData) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(userData),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserData", requestOptions)
            .then((response) => handleResponse(response))
            .then((user) => {
                dispatch(success(user));
                return user;
            });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERPASSWORD_SUCCESS, user };
    }
}

export function sendVerificationMail(emailAddress) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(emailAddress),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/sendVerificationMail", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERPASSWORD_SUCCESS, user };
    }
}

export function updateUserRole(user, userIdToChange, roleToChange) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            user: user,
            userIdToChange: userIdToChange,
            roleToChange: roleToChange,
        }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/updateUserRole", requestOptions).then((user) => {
            dispatch(success(user));
        });
    };

    function success(user) {
        return { type: userConstants.UPDATEUSERROLE_SUCCESS, user };
    }
}

export function getUsersByResellerId(resellerId) {
    console.log(resellerId);
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/getUsersByResellerId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}
