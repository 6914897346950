import React, { Component } from "react";
import store from "../../../store";
import { getAllSubReseller, updateReseller, insertReseller } from "../../../actions/resellerActions";
import DataGrid, { Column, Paging, SearchPanel } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./Resellers.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Resellers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resellers: [],
        };

        this.handleInsert = this.handleInsert.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.usersButton = this.usersButton.bind(this);
    }

    render() {
        return (
            <div className="resellers">
                <h1>Resellers</h1>
                <DataGrid
                    dataSource={this.state.resellers}
                    keyExpr="resellerId"
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onInitialized={this.handleInit}
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Paging enabled={false} />
                    <Column dataField="resellerId" caption={"ID"} allowEditing={false} width="60px" />
                    <Column dataField="resellerName" caption={"Name"} />
                    <Column dataField="licenseName" caption={"Lizens-Name"} allowEditing={false} />
                    <Column
                        dataField={"resellerId"}
                        caption={"Benutzer"}
                        cellRender={this.usersButton}
                        allowEditing={false}
                        width={120}
                    />
                </DataGrid>
            </div>
        );
    }

    usersButton(props) {
        return (
            <div>
                { props.data.Users.length > 0 && <Link to={`${this.props.match.url}/${props.value}`}>
                    <span>Benutzer</span>
                </Link>}
            </div>
        );
    }

    handleInit(e) {
        e.component.beginCustomLoading();
        store.dispatch(getAllSubReseller(this.props.identity.user.resellerId)).then((json) => {
            this.setState({ resellers: json });
            e.component.endCustomLoading();
        });
    }

    handleUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateReseller(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleInsert(e) {
        e.component.beginCustomLoading();
        store.dispatch(insertReseller(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Resellers);
