import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllSubReseller(resellerId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(resellerId)
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/reseller/getAllSubReseller", requestOptions)
            .then(response => handleResponse(response))
            .then(json => { return json });
    };
}
    
    export function updateReseller(data) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };

        return function (dispatch) {
            return fetch(config.backendHost + "/reseller/updateReseller", requestOptions)
                .then(response => handleResponse(response))
        };
    }

    export function insertReseller(data) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(data)
        };

        return function (dispatch) {
            return fetch(config.backendHost + "/reseller/insertReseller", requestOptions)
                .then(response => handleResponse(response))
        };
    }