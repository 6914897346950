import React, { Component } from "react";
import store from "../../../../store";
import DataGrid, { Column, Editing, Paging, Texts, SearchPanel, Lookup } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./WebShops.scss";
import {
    getWebShopsByLicenseId,
    updateWebShop,
    insertWebShop,
    getTakeAwaysByLicenseId,
    updateTakeAway,
    insertTakeAway,
    deleteTakeAway,
    getWebShopTypes,
} from "../../../../actions/webShopActions";
import { getServerUrls } from "../../../../actions/serverUrlActions";

class Webshops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webShops: [],
            takeAwayShops: [],
            webShopTypes: [],
            serverUrls: [],
            licenseId: 0,
        };

        this.handleWebShopsInsert = this.handleWebShopsInsert.bind(this);
        this.handleWebShopsUpdate = this.handleWebShopsUpdate.bind(this);
        this.handleWebShopsInit = this.handleWebShopsInit.bind(this);

        this.handleTakeAwayRemove = this.handleTakeAwayRemove.bind(this);
        this.handleTakeAwayInsert = this.handleTakeAwayInsert.bind(this);
        this.handleTakeAwayUpdate = this.handleTakeAwayUpdate.bind(this);
        this.handleTakeAwayInit = this.handleTakeAwayInit.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            licenseId: id,
        });
        store.dispatch(getWebShopTypes()).then((json) => {
            this.setState({ webShopTypes: json });
        });
        store.dispatch(getServerUrls()).then((json) => {
            this.setState({ serverUrls: json });
        });
    }

    render() {
        return (
            <div className="webshops">
                <div>
                    <h1>FoodGenius Onlineshops</h1>
                    <DataGrid
                        dataSource={this.state.webShops}
                        keyExpr="WebShopId"
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleWebShopsInit}
                        onRowUpdated={this.handleWebShopsUpdate}
                        onRowInserting={this.handleWebShopsInsert}
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />
                        <Editing mode={"form"} allowUpdating={true} allowAdding={true} allowDeleting={false}>
                            <Texts editRow="Bearbeiten" cancelRowChanges="Abbrechen" saveRowChanges="Speichern" />
                        </Editing>
                        <Column dataField="WebShopId" caption={"ID"} allowEditing={false} width="60px" />
                        <Column dataField="StoreName" caption={"Name"} />
                        <Column dataField="Url" caption={"Url"} />
                        <Column dataField="WebShopType" caption={"Webshop-Typ"}>
                            <Lookup
                                dataSource={this.state.webShopTypes}
                                valueExpr="Key"
                                displayExpr="Value"
                                allowClearing={false}
                            />
                        </Column>
                        <Column dataField="WebshopVersion" visible={false} caption={"Webshopversion"} />
                        <Column
                            dataField="WebshopSoftwareVersion"
                            visible={false}
                            caption={"Webshop-Softwareversion"}
                        />
                        <Column dataField="NodePort" visible={false} caption={"NodePort"} />
                        <Column dataField="Username" visible={false} caption={"Benutzername"} />
                        <Column dataField="Password" visible={false} caption={"Passwort"} />
                        <Column dataField="Validitydate" visible={false} dataType="date" caption={"Lizenzablauf"} />
                        <Column dataField="ServerUrl" visible={false} caption={"Server-Url"}>
                            <Lookup
                                dataSource={this.state.serverUrls}
                                valueExpr="url"
                                displayExpr="DisplayName"
                                allowClearing={false}
                            />
                        </Column>
                        <Column dataField="WebSocketUrl" dataType="string" visible={false} caption={"WebSocket URL"} />
                        <Column dataField="AppClientID" ataType="string" visible={false} caption={"AppClient ID"} />
                        <Column dataField="PoolID" ataType="string" visible={false} caption={"Pool ID"} />
                        <Column dataField="xApiKey" ataType="string" visible={false} caption={"xApi Key"} />
                        <Column dataField="access_key" ataType="string" visible={false} caption={"Access Key"} />
                        <Column
                            dataField="secret_access_key"
                            ataType="string"
                            visible={false}
                            caption={"Secret Access Key"}
                        />
                        <Column dataField="bucketName" ataType="string" visible={false} caption={"Bucketname"} />
                    </DataGrid>
                </div>
                <div>
                    <h1>Lieferando Onlineshops</h1>
                    <DataGrid
                        dataSource={this.state.takeAwayShops}
                        keyExpr="TakeAwayId"
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleTakeAwayInit}
                        onRowRemoved={this.handleTakeAwayRemove}
                        onRowUpdated={this.handleTakeAwayUpdate}
                        onRowInserting={this.handleTakeAwayInsert}
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />
                        <Editing mode={"cell"} allowUpdating={true} allowAdding={true} allowDeleting={true}>
                            <Texts
                                deleteRow="Löschen"
                                confirmDeleteMessage="Soll dieser Eintrag wirklich gelöscht werden?"
                            />
                        </Editing>
                        <Column dataField="TakeAwayId" caption={"ID"} allowEditing={false} width="60px" />
                        <Column dataField="TakeAwayRestaurantId" caption={"Lieferando Restaurant ID"} />
                        <Column dataField="serverName" caption={"Servername"} />
                    </DataGrid>
                </div>
            </div>
        );
    }

    handleWebShopsInit(e) {
        const { id } = this.props.match.params;
        e.component.beginCustomLoading();
        store.dispatch(getWebShopsByLicenseId(id)).then((json) => {
            this.setState({ webShops: json });
            e.component.endCustomLoading();
        });
    }

    handleWebShopsUpdate(e) {
        console.log(e);
        e.component.beginCustomLoading();
        store.dispatch(updateWebShop(e.data)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleWebShopsInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.licenseId = this.state.licenseId;
        store.dispatch(insertWebShop(webShop)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayInit(e) {
        const { id } = this.props.match.params;
        e.component.beginCustomLoading();
        store.dispatch(getTakeAwaysByLicenseId(id)).then((json) => {
            this.setState({ takeAwayShops: json });
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(deleteTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.licenseId = this.state.licenseId;
        store.dispatch(insertTakeAway(webShop)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }
}

export default Webshops;
