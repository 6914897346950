import React, { Component } from "react";
import store from "../../../../store";
import DataGrid, { Column, Paging, SearchPanel } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./Users.scss";
import {
   getUsersByResellerId
} from "../../../../actions/userActions";
import { connect } from "react-redux";
import PopupChangePassword from "./PopupChangePassword";

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],            
            resellerId: 0,
            selectedUser: undefined,
            showEditPopup: false,
        };

      this.handleUsersInit = this.handleUsersInit.bind(this);      
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            resellerId: id,
        });       
    }

    render() {
        return (
            <div className="users">
                <div>
                    <h1>User</h1>
                    <DataGrid
                        dataSource={this.state.users}
                        keyExpr={"Id"}
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleUsersInit}   
                        onRowClick={e => {
                            this.setState({
                                selectedUser: e.data,
                                showEditPopup: true
                            });
                        }}                     
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />                       
                        <Column dataField="UserName" caption={"Benutzername"} allowEditing={false} />
                        <Column dataField="FirstName" caption={"Vorname"} />
                        <Column dataField="LastName" caption={"Nachname"} />                        
                    </DataGrid>
                    <PopupChangePassword
                        showEditPopup={this.state.showEditPopup}
                        userData={this.state.selectedUser}                        
                    />
                </div>             
            </div>
        );
    }


    prepareUserData(userData) {
        if (userData.UserRoles.length > 0) {
            var roles = [];
            if (userData.UserRoles[0].Role) {
                roles = userData.UserRoles.map(userRole => {
                    return userRole.Role.Id;
                });
            } else {
                roles = userData.UserRoles;
            }

            userData.UserRoles = roles;
        }

        return userData;
    }

    handleUsersInit(e) {
        const { id } = this.props.match.params;
        e.component.beginCustomLoading();
        store.dispatch(getUsersByResellerId(id)).then((json) => {
            this.setState({ users: json });
            e.component.endCustomLoading();
        });
    }      
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Users);
