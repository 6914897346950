import { Form, Popup, Tabs, LoadPanel, Button, TextBox } from "devextreme-react";
import { Item } from "devextreme-react/form";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserRole, updateUserPassword,  updateUserData } from "../../../../actions/userActions";
import store from "../../../../store";
import notify from "devextreme/ui/notify";
import { getToast } from '../../../../helpers/requestHelpers'
import './PopupChangePassword.scss'
import { CompareRule, RequiredRule, Validator } from "devextreme-react/validator";
const tabs = [
    {
        id: 0,
        text: 'Login Daten',
        content: 'loginData'
    },
    {
        id: 1,
        text: 'Persönliche Daten',
        content: 'userData'
    }
];

class PopupChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            currentTab: tabs[0],
            currentContent: tabs[0].content,
            userData: undefined,
            password: "",
            newPassword: "",
            newPasswordConfirm: "",
        };

        //this.handleTabsOptionChanged = this.handleTabsOptionChanged.bind(this);
        //this.updateUserData = this.updateUserData.bind(this);
        this.updateLoginData = this.updateLoginData.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.passwordComparison = this.passwordComparison.bind(this);
        this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onNewPasswordConfirmChanged = this.onNewPasswordConfirmChanged.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
    }

    static getDerivedStateFromProps(props, state) {

        if (props.userData) {
            if (state.userData === undefined) {
                state.userData = props.userData;
            } else {
                if (state.userData.Email !== props.userData.Email) {
                    state.userData = { ...props.userData, newPassword: "", newPasswordConfirm: "" };
                }
            }
        }
        return state;
    }

    passwordComparison() {
        return this.state.newPassword;
    }

    onOldPasswordChanged(e) {
        this.setState({
            oldPassword: e.value
        });
    }

    onNewPasswordChanged(e) {
        this.setState({
            newPassword: e.value
        });
    }

    onNewPasswordConfirmChanged(e) {
        this.setState({
            newPasswordConfirm: e.value
        });
    }

    render() {

        if (this.props.userData) {
            var firstName = this.props.userData.FirstName ? this.props.userData.FirstName : ""
            var lastName = this.props.userData.LastName ? this.props.userData.LastName : ""
            var fullName = ""
            firstName === "" && lastName === "" ? fullName = "(Kein Name angegeben)" : fullName = firstName + " " + lastName
        }
        return (
            <Popup
                visible={this.props.showEditPopup}
                closeOnOutsideClick={true}
                onHiding={this.props.hide}
                title={fullName}
            >
                <div>
                    {this.state.userData && <div>
                        <Tabs
                            dataSource={tabs}
                            defaultSelectedItem={this.state.currentTab}
                            onOptionChanged={this.handleTabsOptionChanged}
                        />
                        <div className="popupEditUserContent" style={{ marginTop: '15px' }}>
                            <LoadPanel visible={this.state.isLoading} />
                            {this.state.currentContent === "userData" && <div>
                                <Form
                                    formData={this.state.userData}
                                    onFieldDataChanged={this.updateUserData}
                                >
                                    <Item className="updateUserRoleItem" label={{ text: 'Rolle dieses Users' }} dataField={'UserRoles'} editorType={'dxTagBox'} editorOptions={{
                                        dataSource: this.props.roles,
                                        displayExpr: 'role',
                                        valueExpr: 'id'
                                    }} />
                                    <Item label={{ text: 'UserName' }} dataField={'UserName'} editorType={'dxTextBox'} />
                                    <Item label={{ text: 'Vorname' }} dataField={'FirstName'} editorType={'dxTextBox'} />
                                    <Item label={{ text: 'Nachname' }} dataField={'LastName'} editorType={'dxTextBox'} />                                  
                                </Form>
                            </div>}
                            {this.state.currentContent === "loginData" && <div className="loginDataForm">
                                <form
                                // formData={this.state.userData}
                                // onFieldDataChanged={this.updateLoginData}
                                >
                                    {/* <Item className="updateUserRoleItem" label={{ text: 'Rolle dieses Users' }} dataField={'UserRoles'} editorType={'dxTagBox'} editorOptions={{
                                        dataSource: this.props.roles,
                                        displayExpr: 'role',
                                        valueExpr: 'id'
                                    }} />

                                    {this.props.userData.Id === this.props.identity.user.Id &&
                                        <Item className="dxTextBoxPassword" label={{ text: 'Altes Passwort' }} dataField={'oldPassword'} editorType={'dxTextBox'} editorOptions={{ mode: "password" }} />}
                                    <Item className="dxTextBoxPassword" label={{ text: 'Neues Passwort' }} dataField={'newPassword'} editorType={'dxTextBox'} editorOptions={{ mode: "password" }} />
                                    <Item className="dxTextBoxPassword" label={{ text: 'Neues Passwort wiederholen' }} dataField={'newPasswordConfirm'} editorType={'dxTextBox'} editorOptions={{ mode: "password" }} />
                                 */}
                                    <div className="dx-fieldset">
                                        <div className="dx-fieldset-header">Zugangsdaten</div>
                                        {this.props.userData.Id === this.props.identity.user.Id &&
                                            <div className="dx-field">
                                                <div className="dx-field-label">Altes Passwort</div>
                                                <div className="dx-field-value">
                                                    <TextBox
                                                        mode="password"
                                                        value={this.state.oldPassword}
                                                        onValueChanged={this.onOldPasswordChanged}>
                                                        <Validator>
                                                            <RequiredRule message="Password is required" />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                            </div>
                                        }
                                        <div className="dx-field">
                                            <div className="dx-field-label">Neues Passwort</div>
                                            <div className="dx-field-value">
                                                <TextBox
                                                    mode="password"
                                                    value={this.state.newPassword}
                                                    onValueChanged={this.onNewPasswordChanged}>
                                                    <Validator>
                                                        <RequiredRule message="Password is required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label"> Neues Passwort wiederholen</div>
                                            <div className="dx-field-value">
                                                <TextBox mode="password" value={this.state.newPasswordConfirm} onValueChanged={this.onNewPasswordConfirmChanged}>
                                                    <Validator>
                                                        <RequiredRule message="Dieses Feld wird benötigt" />
                                                        <CompareRule message="Die beiden Passwörter stimmen nicht überein" comparisonTarget={this.passwordComparison} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                                <p className="passwordHint">Wichtig: Passwörter müssen mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten!</p>
                                <div className="changePasswordButtonDiv">
                                    <Button
                                        className="changePasswordButton"
                                        text="Passwort ändern"
                                        type="default"
                                        disabled={this.state.newPassword !== this.state.newPasswordConfirm || (this.state.oldPassword === "" && this.props.userData.Id === this.props.identity.user.Id) || this.state.newPasswordConfirm === "" || this.state.newPassword === ""}
                                        onClick={this.changePassword}
                                    />
                                </div>
                            </div>}
                        </div>
                    </div>}
                </div>
            </Popup>
        );
    }

    /*
    handleTabsOptionChanged(e) {

        if (e.value[0]) {
            this.setState({
                currentTab: e.value[0],
                currentContent: e.value[0].content
            });
        }
    }
    */

    updateUserData(e) {
        this.setState({ isLoading: true })

        store.dispatch(updateUserData({ ...this.state.userData, [e.dataField]: e.value }))
            .then(result => {
                notify(getToast("Änderungen erfolgreich gespeichert", "success"));
                this.setState({ isLoading: false, ...this.state.userData, [e.dataField]: e.value })
            });


    }

    changePassword() {
        var oldPassword = this.state.oldPassword
        var newPasswordConfirm = this.state.newPasswordConfirm

        if (this.state.isLoading === false) {
            this.setState({ isLoading: true })
            store.dispatch(updateUserPassword({ newPassword: newPasswordConfirm, Id: this.state.userData.Id, oldPassword: oldPassword }))
                .then(result => {
                    if (result.status === 200) {
                        notify(getToast("Passwort erfolgreich geändert", "success"));
                        this.setState({ isLoading: false, newPassword: "", oldPassword: "", newPasswordConfirm: "" })
                    }
                    else {
                        notify(getToast(result.errorMessage, "error"));
                        this.setState({ isLoading: false })
                    }
                })
        }
    }

    updateLoginData(e) {
        if (e.dataField === "UserRoles") {
            this.setState({ isLoading: true });

            store.dispatch(updateUserRole(this.state.userData.Id, e.value))
                .then(result => {
                    notify(getToast("Änderungen erfolgreich gespeichert", "success"));
                    this.setState({ isLoading: false, ...this.state.userData, [e.dataField]: e.value })
                });
        }

        if (e.dataField === "oldPassword" || e.dataField === "newPassword" || e.dataField === "newPasswordConfirm") {
            var oldPassword = this.state.userData.oldPassword
            var newPassword = this.state.userData.newPassword
            var newPasswordConfirm = this.state.userData.newPasswordConfirm

            if (newPassword !== undefined && newPasswordConfirm !== undefined) {
                if (newPassword === newPasswordConfirm) {
                    if (this.state.isLoading === false) {
                        this.setState({ isLoading: true })
                        store.dispatch(updateUserPassword({ newPassword: newPasswordConfirm, Id: this.state.userData.Id, oldPassword: oldPassword }))
                            .then(result => {
                                notify(getToast("Passwort erfolgreich geändert", "success"));
                                this.setState({ isLoading: false, userData: { ...this.state.userData, newPassword: undefined, oldPassword: undefined, newPasswordConfirm: undefined } })
                            })
                    }
                }
                else {
                    notify(getToast("Die beiden Passwörter stimmen nicht überein", "error"))
                }
            }
        }

    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity
    };
}

export default connect(mapStateToProps)(PopupChangePassword);